<template >
  <div class="app-privacy">
    <div style="margin: 0 20px">
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold"
          >大醫隱私政策</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
      </p>
      <p style="line-height: 9pt; margin: 0pt; text-align: right">
        <span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >更新日期∶202</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >2</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >年</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >12</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >月</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >4</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >日</span
        >
      </p>
      <p
        style="
          font-size: 9pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: right;
          widows: 0;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >生效日期∶202</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >2</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >年</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >12</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >月</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >4</span
        ><span
          style="
            font-family: 宋体;
            font-size: 9pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >日</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
      </p>
      <p style="font-size: 10.5pt; line-height: 115%; margin: 0pt 0pt 10pt">
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >開發者：中醫在綫有限公司</span
        >
      </p>
      <p style="font-size: 10.5pt; line-height: 115%; margin: 0pt 0pt 10pt">
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >註冊地址：香港數碼港道100號數碼港3座F區5樓 郵箱210號</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >（以下簡稱“</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >”或“我們”）尊重用戶的個人隱私，並會</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >努</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >力</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >保障</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >您個人信息安全可靠，也感謝您對我們的信任。我們致力於維持您對我們的信任，並將恪守以下原則，保護您的個人信息：</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >為此</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >我們承諾，我們將按業界通行的安全標準並儘最大的商業努力，採取相應的安全保護措施來保護您的個人信息。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >適用範圍：</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >本政策僅適用於</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >所有</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >的</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >用戶。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >本政策僅適用於</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >相關的產品和服務。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >&nbsp;</span
        ><span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p style="line-height: 10.5pt; margin: 0pt">
        <span
          style="
            color: #4c4c4c;
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-decoration: underline;
            vertical-align: baseline;
          "
          >【特別提示】本政策與您使用我們的服務關係緊密，請您在使用我們的產品/服務前，仔細閱讀並充分理解本政策，做出您認為適當的選擇。對本政策中與您的權益存在重大關係的條款和個人敏感信息，我們將採用粗體字進行標註以提示您注意。為給您帶來更好的產品和服務體驗，我們在持續努力改進我們的技術，隨之我們可能會不時推出新的或優化後的功能，可能需要收集、使用新的個人信息或變更個人信息使用目的或方式。對此，我們將通過更新本政策、彈窗、頁面提示等方式另行向您說明對應信息的收集目的、範圍及使用方式，並為您提供自主選擇同意的方式，且在徵得您明示同意後收集、使用。</span
        >
      </p>
      <p style="line-height: 10.5pt; margin: 3pt 0pt 8pt">
        <span
          style="
            color: #807f7f;
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-decoration: underline;
            vertical-align: baseline;
          "
          >如對本隱私政策或相關事宜有任何問題，您可隨時通過訪問我們的在線客服系統與我們聯繫。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >本隱私政策將幫助您了解以下內容：</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >一、我們如何收集和使用您的個人信息</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >二 、我們如何存儲和保護您的個人信息</span
        >
      </p>

      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >三、您的權利</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >四</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >、如何联系我們</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >一、我們如何收集和使用您的個人信息</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >在您使用</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >平台及服務的過程中，您理解並同意，我們將根據下列不同場景下的相應目的收集、使用您特定種類的個人信息（含敏感個人信息）。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          background-color: #ffffff;
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            background-color: #ffffff;
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >根據《中華人民共和國個人信息保護法》及《中華人民共和國網絡安全法》的規定：</span
        ><br /><span
          style="
            background-color: #ffffff;
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >1.</span
        ><span
          style="
            background-color: #ffffff;
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >個人信息</span
        ><span
          style="
            background-color: #ffffff;
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >是以電子或者其他方式記錄的與已識別或者可識別的自然人有關的各種信息，不包括匿名化處理後的信息。包括但不限於自然人的姓名、出生日期、身份證件號碼、個人生物識別信息、住址、電話號碼等。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >2.</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >敏感個人信息</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >是一旦洩露或者非法使用，容易導致自然人的人格尊嚴受到侵害或者人身、財產安全受到危害的個人信息，包括生物識別、宗教信仰、特定身份、醫療健康、金融賬戶、行踪軌蹟等信息，以及不滿十四周歲未成年人的個人信息。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >這些信息將用於：</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p style="font-size: 10.5pt; line-height: 115%; margin: 0pt 0pt 10pt">
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >【1】保障產品基礎功能的正常運行；</span
        >
      </p>
      <p style="font-size: 10.5pt; line-height: 115%; margin: 0pt 0pt 10pt">
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >【2】保障產品、服務以及用戶使用安全；</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >我們收集與使用的個人信息</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >我們會按照如下方式收集您在使用服務時主動提供的，以及您在使用功能或接受服務過程中產生的信息：</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >1.</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >保障產品基礎功能的正常運行</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >當您使用</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >平台及相關服務時，為了保障軟件與服務的正常運行，我們會收集您的</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >硬件型號、操作系統版本號、軟件版本號、網絡接入方式及類型、操作日誌、當前正在運行的程序</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >信息。</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >請您了解，這些信息是我們提供服務和保障產品正常運行所必須收集的基本信息。</span
        >
      </p>

      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >您使用</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >平台的搜索服務時，我們會收集您的</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >搜索關鍵字信息和日誌記錄</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >。為了提供高效的搜索服務，部分前述信息會暫時存儲在您的本地存儲設備之中，並可向您展示搜索結果內容、搜索歷史記錄。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <a name="OLE_LINK2"></a
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >2</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >.保障產品、服務及用戶使用安全</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >為了開展數據分析和更好改善</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >的服務，我們會收集您的日誌信息（包括</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >瀏覽記錄、檢索內容、點擊查看記錄、交易記錄以及IP地址、通訊錄、短信、通話記錄、相機信息、軟件安裝列表、瀏覽器類型、電信運營商、使用語言、訪問日期和時間</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >）及設備信息（包括</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
        >
          设备型号、设备设置、MAC地址以及IMEI、IDFA、ANDROID
          ID、OAID設備標識符、操作系統、系統語言、分辨率、電信運營商、SIM卡IMSI信息、SIM卡歸屬地、設備環境、傳感器、ICCID</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >信息），以及使用軟件及相關服務的</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >頻率、崩潰數據、總體安裝、使用情況、性能數據</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >信息，用於識別非法用戶及黑色產業鏈用戶，提升用戶安全性及整體</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >公平性，一切收集行為皆處於合理功能收集範圍。</span
        >
      </p>

      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >二、我們如何存儲和保護您的個人信息</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >(一)信息存儲的地點</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >我們依照法律法規的規定，將在中華人民共和國境內運營過程中收集和產生的您的個人信息存儲於</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >中華人民共和國境內</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >。目前，我們不會將上述信息傳輸至境外，如果我們向境外傳輸，我們會按照法律法規和相關監管部門的規定及本政策“七、您的個人信息如何在全球範圍轉移”要求，向您說明個人信息出境的目的以及涉及的個人信息類型，徵得您的同意，並通過簽訂協議、現場核查等有效措施，要求境外機構對所獲得的個人信息嚴格保密。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >(二)存儲期限</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >我們僅在為提供</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >平台及服務之目的所必需的期間內保留您的個人信息，超出必要期限後，我們將對您的個人信息進行刪除或匿名化處理，但法律法規另有規定的除外。關於個人信息刪除的相關約定，詳見本政策“五。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >（三）保護措施</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >我們採取了內部管理保障措施和多方面的技術保障措施保護您的個人信息，具體而言包括但不限於：</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">1.</span
        ><span style="font-family: 宋體; font-size: 10.5pt">&nbsp;</span
        ><span style="font-family: 宋体; font-size: 10.5pt"
          >建立針對個人數據安全的縱深防禦體系，數據從創建、存儲、使用、傳輸、歸檔、銷毀各環節上均採用管理與技術的雙重控制措施，避免集中性的數據操作風險。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">2.</span
        ><span style="font-family: 宋體; font-size: 10.5pt">&nbsp;</span
        ><span style="font-family: 宋体; font-size: 10.5pt"
          >用戶個人敏感信息被加密存儲並通過數據隔離措施降低數據的重標識風險。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">3.</span
        ><span style="font-family: 宋體; font-size: 10.5pt">&nbsp;</span
        ><span style="font-family: 宋体; font-size: 10.5pt"
          >使用加密傳輸協議，保障數據傳輸安全。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">4.</span
        ><span style="font-family: 宋體; font-size: 10.5pt">&nbsp;</span
        ><span style="font-family: 宋体; font-size: 10.5pt"
          >嚴格控制數據訪問權限，設立完善的敏感數據訪問權限申請、審批制度。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">5.</span
        ><span style="font-family: 宋體; font-size: 10.5pt">&nbsp;</span
        ><span style="font-family: 宋体; font-size: 10.5pt"
          >建立數據安全監控和安全審計，進行全面數據安全控制。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >6</span
        ><span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >.</span
        ><span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >內部機制：我們設立了個人信息保護專員和信息安全委員會，針對個人信息收集、使用、共享、委託處理等開展個人信息安全影響評估。同時，我們不斷對工作人員培訓相關法律法規及隱私安全準則和安全意識強化宣導，並組織全體工作人員參加安全考試。另外，我們的系統通過了國家網絡安全等級保護的測評。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >（四）個人信息安全事件的處理</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >互聯網環境並非百分之百安全，我們將盡力確保您所提供的信息的安全性，並已製定相關的信息安全事件應急預案。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >如不幸發生個人信息安全事件，我們將按照法律法規的要求，及時以</span
        ><span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >APP</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >推送通知、發送郵件、短消息、電話方式，向您告知：安全事件的基本情況和可能的影響、我們已採取或將要採取的處置措施、您可自主防範和降低風險的建議、對您的補救措施等。難以逐一告知個人信息主體時，我們會採取合理、有效的方式發佈公告。同時，我們還將按照監管部門要求，主動上報個人信息安全事件的處置情況。若您的合法權益受損，我們將承擔相應的法律責任。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >請您務必妥善保管好您的賬戶名、密碼及其他個人信息。您在使用我們的服務時，我們會通過這些信息來識別您的身份。一旦您洩漏了前述信息，您可能會蒙受損失，並可能產生對您不利的法律後果。如您發現您的賬戶或個人信息可能或已經洩露時，請您立即通過本政策開頭及末尾提供的聯繫方式和我們取得聯繫，以便我們及時採取相應措施以避免或降低相關損失。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          background-color: #ffffff;
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            background-color: #ffffff;
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >三、您的權利</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >按照中國相關的法律、法規、標準，以及其他國家、地區的通行做法，我們保障您對自己的個人信息行使以下權利：訪問個人信息、更正個人信息、刪除個人信息、註銷賬戶</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >等權利，在手機關閉隱私權限等。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >(一)訪問您的個人信息</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >您有權訪問您的個人信息，法律法規規定的例外情況除外。如果您想行使數據訪問權，可以通過以下方式自行訪問：</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >在以下情形中，按照法律法規要求，我們將無法響應您的請求：</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >1.與國家安全、國防安全有關的；</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >2.與公共安全、公共衛生、重大公共利益有關的；</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >3.與犯罪偵查、起訴和審判等有關的；</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >4.有充分證據表明您存在主觀惡意或濫用權利的；</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >5.響應您的請求將導致您或其他個人、組織的合法權益受到嚴重損害的。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >（二）主動刪除個人信息、註銷賬戶</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >當您需要刪除個人信息或註銷賬戶</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >時，您可以自主聯繫我們進行處理，我們將按照法律、法規規定的為提供我們的服務之目的所必須的最短期間內保存您的個人信息，但您要求刪除個人信息或註銷賬戶的、或法律法規另有規定的除外，當您的個人信息超出上述保存期限，我們將根據法律法規的要求刪除您的個人信息或作匿名化處理。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >四</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >、如何联系我們</span
        >
      </p>
      <p style="line-height: 10.5pt; margin: 0pt">
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >如果您對於我們的個人信息處理行為存在任何投訴舉報需求，您可以通過以下聯繫方式與我們聯繫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            vertical-align: baseline;
          "
          >，我們將在驗證您身份的15天內答复您的請求並儘力解決。</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt;
          text-align: justify;
        "
      >
        <span
          style="
            font-family: Calibri;
            font-size: 10.5pt;
            font-style: normal;
            text-transform: none;
          "
          >&nbsp;</span
        >
      </p>
      <p style="margin: 0pt; text-align: justify">
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >Whatsapp：</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >93839432</span
        >
      </p>
      <p style="margin: 0pt; text-align: justify">
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >電子郵件：</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >info</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >@</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >ectcm</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >.com</span
        >
      </p>
      <p style="margin: 0pt; text-align: justify">
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >聯繫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >電話：</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            text-transform: none;
          "
          >(852) 3590 4880</span
        >
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
      </p>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
      </p>
      <p style="line-height: 10.5pt; margin: 20pt 0pt 0pt">
        <span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
          >大醫</span
        ><span
          style="
            font-family: 宋体;
            font-size: 10.5pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
          >涉及第三方SDK情況說明</span
        >
      </p>
      <table
        cellspacing="0"
        cellpadding="0"
        style="border-collapse: collapse; margin-left: 0pt; width: 100%"
      >
        <tbody>
          <tr style="height: 27.75pt">
            <td
              colspan="2"
              style="
                border-bottom-color: #999999;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                border-left-color: #999999;
                border-left-style: solid;
                border-left-width: 0.75pt;
                border-right-color: #dddddd;
                border-right-style: solid;
                border-right-width: 0.75pt;
                border-top-color: #999999;
                border-top-style: solid;
                border-top-width: 0.75pt;
                padding: 2.62pt 2.62pt 1.88pt;
                vertical-align: middle;
                width: 447.75pt;
              "
            >
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >阿里雲IM SDK</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK類型：即時通信類</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK描述：阿里雲IM SDK</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK使用業務場景：通知使用者消息</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK所需用戶信息字段：網絡切換、相機、錄音、讀取日誌、藍牙、安裝、外部存儲、設備信息</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK使用目的、理由：後台與用戶、用戶與用戶通過IM溝通</span
                >
              </p>
            </td>
          </tr>
          <tr style="height: 27.75pt">
            <td
              colspan="2"
              style="
                border-bottom-color: #999999;
                border-bottom-style: solid;
                border-bottom-width: 0.75pt;
                border-left-color: #999999;
                border-left-style: solid;
                border-left-width: 0.75pt;
                border-right-color: #dddddd;
                border-right-style: solid;
                border-right-width: 0.75pt;
                border-top-color: #999999;
                border-top-style: solid;
                border-top-width: 0.75pt;
                padding: 2.62pt 2.62pt 1.88pt;
                vertical-align: middle;
                width: 447.75pt;
              "
            >
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >騰訊</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >b</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >ugly SDK</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK類型：</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >異常上報</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK描述：</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >將</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >app</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >使用期間所產生的異常數據做上報</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK使用業務場景：</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >應用崩潰</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >、</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >閃退</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >、</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >異常報錯等信息上報</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK所需用戶信息字段：</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >MAC地址</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >、</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >WIFI</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >信息</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >、</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >ss</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >id、</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >設備ID</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >（IMEI）</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK使用目的、理由：</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >優化異常位置</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >，</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >增進用戶體驗</span
                >
              </p>
              <p style="line-height: 9pt; margin: 0pt">
                <span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >SDK</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >收集規則</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >：</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >每</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >3s</span
                ><span
                  style="
                    color: #757575;
                    font-family: 宋体;
                    font-size: 9pt;
                    font-style: normal;
                    vertical-align: baseline;
                  "
                  >收集一次信息</span
                >
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        style="
          font-size: 10.5pt;
          line-height: 115%;
          margin: 0pt 0pt 10pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
.app-privacy {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #fff;
}
</style>
